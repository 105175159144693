var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "socialButtons" },
    [
      _c(
        "g-signin-button",
        {
          attrs: { params: _vm.googleSignInParams },
          on: {
            success: _vm.onGoogleSignInSuccess,
            error: _vm.onGoogleSignInError,
          },
        },
        [
          _c(
            "button",
            { staticClass: "btn btn--social-login" },
            [
              _c("icon", { attrs: { name: "google", original: "" } }),
              _vm._v("Log in with Google"),
            ],
            1
          ),
        ]
      ),
      _c(
        "fb-signin-button",
        {
          attrs: { params: _vm.fbSignInParams },
          on: {
            success: _vm.onFacebookSignInSuccess,
            error: _vm.onFacebookSignInError,
          },
        },
        [
          _c(
            "button",
            { staticClass: "btn btn--social-login" },
            [
              _c("icon", { attrs: { name: "facebook", color: "#3b5998" } }),
              _vm._v("Log in with Facebook"),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }